<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="content">Congratulations, you have qualified in the ATOM Rewards campaign! </h3>
        <h3 class="content">Your Rewards: {{airdropValue}} IRIS</h3>
      </div>
      <div class="footer">
         <button
          class="sub v-btn uc"
          submit
          @click="claimLater"
        >
       Claim Later
        </button>
        <button
          class="sub v-btn uc "
          :class="{ 'sub-dis': isPay }"
          submit
          @click="claimNow"
        >
        Claim Now
        </button>
      </div>
      <div class="bottom">
        <h3 class="content">Please claim before 2022.12.31 23:59:59 UTC+8</h3>
        <h3 class="content">After claiming, the reward will be airdropped to you in few days.</h3>
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
      <v-dialog v-model="openAppeal" persistent>
         <accountAppeal @getOpen="OpenApp"></accountAppeal>
      </v-dialog>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import AccountAppeal from "@/components/popup/accountAppeal.vue";
export default {
  data: () => ({
     openAppeal: false,
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    airdropValue:''
  }),
  components: {
    AccountAppeal,
   
	},
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
if(!this.$store.state.did){
	return
}
      let params={
          // did:"iaa1wzu2k2tsghgj32h5a6allhf69jy2jg2quhlnvd",
          id:"207",
       did:this.$store.state.did
     };
	 
      let satisfy = await api.home.getPromoUser(params)
      this.airdropValue = satisfy.data.grantPrice
      console.log("wxl --- 0000",this.airdropValue)
  
  },
  methods: {
    async claimNow() {   
		if(!this.$store.state.did){
			return
		}
      this.isPay = true
      let params = {
            // did:"iaa1wzu2k2tsghgj32h5a6allhf69jy2jg2quhlnvd",
            id:"207",
            did:this.$store.state.did

        }
     let res =  await api.home.applyPromo(params)
        //   let param = { address: this.$store.state.did };
        //  let res =  await api.home.receiveAirdrop(param)
        //  console.log("wxl -- res",res)
         if(res.success == true){
             this.$toast("success", "Submited").then(()=>{
               this.isPay = false
               this.$emit("getOpen", false);
             })
             setTimeout(()=>{
              window.location.reload(); 
             },2000)
         
             
         }else{
             this.$toast("error", "res.msg")
         }
    
    },
    claimLater(){
        this.$emit("getOpen", false);
    },
       //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
     OpenApp(e) {
       console.log("wxl ---- OpenApp",e)
         this.openAppeal = e;
         this.$emit("getOpenBan", false);
      },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;

    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 8px auto 0px;
      width: 450px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;


    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 4px auto 0px;
      width: 500px;
      min-height: 22px;
      font-family: Helvetica;
      font-size:14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 20px;
      width: 240px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 135px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

