<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
            <img src="@/assets/promoImage/uptick_c6.jpeg" alt="" />
        </div>
        <div class="timer d-flex flex-lg-row" v-if="!isConstellationEnd">
          <div class="days">
            <div class="top">
              <span class="mr-1">{{d1}}</span>
              <span class="mr-2">{{d2}}</span>
            </div>
            <div class="bom">
              DAY
            </div>
          </div>
          <div class="mao">:</div>
          <div class="hours">
            <div class="top">
              <span class="mr-1 ml-2">{{h1}}</span>
              <span class="mr-2">{{h2}}</span>
            </div>
            <div class="bom">
              HR
            </div>
          </div>
          <div class="mao">:</div>
          <div class="minutes">
            <div class="top">
              <span class="ml-2 mr-1">{{m1}}</span>
              <span class="mr-2">{{m2}}</span>
            </div>
            <div class="bom">
              MIN
            </div>
          </div>
          <div class="mao">:</div>
          <div class="seconds">
            <div class="top">
              <span class="ml-2 mr-1">{{s1}}</span>
              <span class="mr-2">{{s2}}</span>
            </div>
            <div class="bom">
              SEC
            </div>
          </div>
        </div>
        <div class="btn mt-6 ">
          <!-- 未登录 -->
            <button class="claim " v-if="!this.$store.state.did" >{{ $t("admin_activity_btn_claim") }}</button>
            <!-- 集合收集阶段 -->
             <button class="notClaim" v-else-if="!isConstellationEnd && !isEnd" @click="collectClicked">Collect</button>
            <!-- 名单统计阶段 -->
             <button class="claim" v-else-if="isConstellationEnd && !isStart && !isEnd">{{ $t("admin_activity_btn_claim") }}</button>
            <!-- 领取阶段 -->
            <button class="notClaim" v-else-if="isConstellationEnd && isStart && !isEnd&& isClicked"  @click="onClicked">{{ $t("admin_activity_btn_claim") }}</button>
			<!-- 领取阶段 已领取或者没有资格领取 -->
			         <button class="claim" v-else-if="isConstellationEnd && isStart && !isEnd&& !isClicked">{{ claimMsg}}</button>
					 
            <!-- 活动结束 -->
              <button class="claim " v-else>{{ $t("token_state_end") }}</button>

          <!-- <button class="claim" v-if="!isStart && !isClicked && isJurisdiction &&this.$store.state.did && !isConstellationEnd">{{ $t("admin_activity_btn_nostart") }}</button>
           <button class="notClaim" v-else-if="isStart && !isClicked && isJurisdiction && !isConstellationEnd&&this.$store.state.did && !isConstellationEnd" @click="onClicked">{{ $t("admin_activity_btn_claim") }}</button>
           <button class="claim" v-else-if="isStart && isClicked && isJurisdiction && !isConstellationEnd&&this.$store.state.did && !isConstellationEnd">{{ $t("admin_activity_btn_claimed") }}</button>
             <button class="claim" v-else-if="isConstellationEnd">{{ $t("token_state_end") }}</button>
          <button class="claim" v-else>{{ $t("admin_activity_btn_claim") }}</button>   -->
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="ava d-flex flex-row">
          <Avatar
            size="41"
            did="iaa1utsw7gm82s5wecmvthjg5p58r6dmf7s8s0ls0z"
            hash="QmQmPxA9FpfMU7DKCSSwmTrdcTFyMVHyWrhXYA7BeawYjZ"
            imgType="smallv"
            :grade="3"
            showAvatarInfo
          />
          <div class="ml-4 name">Uptick Official</div>
        </div>
        <div class="title mt-2 mb-5">
          <span>Twelve Constellation Series: Uptick Zodiac | Gen 2</span>
          <!-- <span class="" v-html="this.$t('admin_activity_title')"></span> -->
        </div>
        <div class="desc mb-8">
          <div class=" desc1 mb-lg-6">The [Uptick Zodiac | Gen 2] NFT is a hidden version within Uptick's 2022 Twelve Constellation Series; designed and issued by the official Uptick Network team. </div> 
          <div class="desc1 mb-lg-6">Holders will be granted different privileges and benefits within the Uptick ecosystem, including being added to the whitelist for the upcoming Uptick Mainnet airdrop. </div> 
          <div class="desc1 ">Once ready, holders of this NFT can claim $UPTICK tokens worth $150.</div>
          <!-- <span class="" v-html="this.$t('admin_activity_title1')"></span> -->
        </div>
         <div class="claimtime mb-lg-6">
          <div> <span>Collect Time:</span>  2023 2.16 14:00 - 3.16 14:00 UTC+8 </div>
          <div> <span>Claim Time:</span> 2023 3.16 17:00 - 4.16 17:00 UTC+8</div>
        </div> 
         <div class="prize">
          <div>
            <div class="rules mb-3">Claim Instructions:</div>  
           
              <div class="titles">1) This NFT is only available to collectors who have acquired the entire Gen 2 'Twelve Constellation 2022 Series' of NFTs. There are 12 NFTs in total, and these all must be collected before: 2023.3.16 14:00 UTC+8. </div>
               <div class="titles">2) After you've submitted your claim, please kindly wait patiently for the NFT to be transferred to you. </div>
                <div class="titles">3) Eligible collectors need to claim their NFT before: 2023 4.16 17:00 UTC+8. Those who fail to claim within the time period will be deemed to have forfeited automatically.</div>
          </div>
        </div>
      </div>
    </div>
      <v-dialog v-model="PromoClaim ">
         <promoClaim @getOpen="openPromoClaim "></promoClaim>
      </v-dialog>
	  <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/index.vue";
import  api  from "@/api"
  import PromoClaim from "@/components/popup/promoClaim.vue";
export default {
  components: { Avatar,PromoClaim },
  data() {
    return {
      d1:'',
      d2:'',
      h1:'',
      h2:'',
      m1:'',
      m2:'',
      s1:'',
      s2:'',
      value: {
        did: "iaa1utsw7gm82s5wecmvthjg5p58r6dmf7s8s0ls0z",
        profilePhoto: "QmQmPxA9FpfMU7DKCSSwmTrdcTFyMVHyWrhXYA7BeawYjZ",
      },
      isConstellationEnd:false,
      isStart:false,
      isEnd:false,
      isClaimed:false,
      isClicked:false,
      PromoClaim: false,
      claimMsg:""
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
   async mounted() {
    this.Time()
      var timestamp = Date.parse(new Date()).toString();
      let nowTimeStamp = timestamp.substr(0, 10);
      // 结束收集时间 1678946400
      let collectEndTimeStamp = 1678946400; //
      //领取开始时间 1678957200
      let claimStartTimeStamp = 1678957200
      //领取结束时间 1681635600000
      let claimEndTimeStamp = 1681635600
     
      if (nowTimeStamp > collectEndTimeStamp) {
        this.isConstellationEnd = true;
      } else {
        this.isConstellationEnd = false;
      }

    

     // 判断领取状态
      if(nowTimeStamp < claimStartTimeStamp){
        this.isStart = false
     
      }else {
         this.isStart = true
		  this.isEnd = false
         if(nowTimeStamp > claimEndTimeStamp){
           this.isEnd = true
         }else{
			 let params={
         id:"208",
			 }
			 let res = await api.home.getPromoUser(params)
			 if(res.success)
			 {
			   this.isClicked = true
			 }else{
				 this.claimMsg=res.msg;
			   this.isClicked = false
			 }
		 }
  }

  
     
   },
  methods: {
	  async onClicked(){
	    // this.isClicked = true
	    // this.PromoClaim = true
	      let params = {
	          id:"208"
	      }
	     let res= await api.home.applyPromo(params)
		 if (res.success === true) {
			 
			 this.$toast("success", "Your claim has been submitted, please kindly wait patiently for the NFT to be transferred to you.")
			 this.isClicked=false
			 this.claimMsg="Claimed";
		 }else{
			  this.$toast("error", "Please do not submit repeatedly")
		 }
	  },
    collectClicked(){

        window.open('https://irisnet.upticknft.com/folder/UptickTwelveConstellationSeriesGen2','_blank');
    },
  
    getTime() {
      // 摄像作品结束时间
      let collectEndTimeStamp = 1678946400000;
      this.now = new Date().valueOf()
    

      this.end = collectEndTimeStamp;

      if (this.now < this.end) {
        this.seconds = (this.end - this.now) / 1000;

      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      let day =  d < 10 ? "0" + d :  d;
      this.d1 = String(day).split("")[0]
      this.d2 = String(day).split("")[1]
      //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      let hour = h < 10 ? "0" + h : h;
      this.h1 = String(hour).split("")[0]
      this.h2 = String(hour).split("")[1]
      let m = parseInt((this.seconds / 60) % 60);
      let minutes = m < 10 ? "0" + m : m;
      this.m1 = String(minutes).split('')[0];
      this.m2 = String(minutes).split('')[1];
      let s = parseInt(this.seconds % 60);
      let second = s < 10 ? "0" + s : s;
      this.s1 = String(second).split("")[0]
      this.s2 = String(second).split("")[1]
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
    openPromoClaim(e){
      this.PromoClaim = e
    }
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      width: 560px;
      // height: 550px;
      img {
        width: 560px;
        height: 550px;
        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer{
        // max-width:305px;
        // margin:17px auto 21px;
        justify-content: center;
        margin-top:17px;
        .mao{

          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom{
          font-family:Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align:center;
          margin-top:7px;
        }
        span{
          width:27px;
          height:36px;
          display:inline-block;
          text-align:center;
          line-height:36px;
          background-color: #270645;
          border-radius: 5px;
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .btn {
        margin-left: 137px;
        .claim {
            width: 276px;
        height: 51px;
        border-radius: 25px;
        text-align: center;
      	background-image: linear-gradient(
		#766983,
		#766983),
	linear-gradient(
		#270645,
		#270645);
	background-blend-mode: normal,
		normal;
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .notClaim{
                width: 276px;
        height: 51px;
   
        border-radius: 25px;
        text-align: center;
          background-image: linear-gradient(
		#270645, 
		#270645), 
	linear-gradient(90deg, 
		#d300fd 0%, 
		#a439fe 26%, 
		#7471ff 52%, 
		#00fdcf 100%), 
	linear-gradient(
		#270645, 
		#270645);
	background-blend-mode: normal, 
		normal, 
		normal;
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;

        }
      }
    }
    .right {
      width: 588px;
      // height: 613px;
      .ava {
        .name {
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .title {
        span {
          width: 575px;
	        height: 124px;
          font-family: Helvetica;
          font-size: 42px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 70px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .desc1 {
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
     .claimtime {
          span{
            font-family: Helvetica;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        div {
          font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .prize {
       .rules{
           font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
       .titles{
           font-family: Helvetica;
            font-size: 13px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

       }
      }
    }
  }
  &.mobile{
    .content{
      margin-top:30px;
      .left{
          max-width:100%;
          min-height:437px;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            cursor: pointer;
          }
          .btn{
            margin-left: 63px;
            width:80%;
            .claim{
              width:80%;
            }
            .notClaim{
              width:80%;
            }
          }
      }
      .right{
          max-width:100%;
          min-height: 613px;
          .title{
              span{
           
            font-size:25px;
            line-height:40px;
              }
          }
          .claimtime{
            height:456px;
          }
      }
    }

  }
}
</style>
